import React, {useContext, useState} from "react";
import {SendCloseDialogEvent, SendEventMessage, useMessageReceiveListener} from "../../helper/ParentPage";
import {localeTexts} from "../../configs/localizations";
import {AppConfigModel, AppSetupModel, MerchantConfigModel} from "../../types/AppSetupModel";
import InfoBox from "../InfoBox";
import {APIs} from "../../configs/apis";


const AppSetupContext = React.createContext(new AppSetupModel());

export function useAppSetup(): AppSetupModel{
    return useContext(AppSetupContext);
}


export function AppSetupProvider({children}: any){
    // Default Failure Page
    let failedPage = (content: any)=> <InfoBox buttonText="Cancel" onClick={()=>SendCloseDialogEvent()} asSinglePageInCenter={true}>{content}</InfoBox>;

    // States
    let [merchantConfig, setMerchantConfig] = useState<MerchantConfigModel>(new MerchantConfigModel());
    let [appConfig, setAppConfig] = useState<AppConfigModel>(new AppConfigModel());
    let [currentView, setCurrentView] = useState(failedPage(localeTexts.page_not_found));

    // Update global Config
    let updateAppConfig = (newAppState: AppConfigModel)=> setAppConfig({...appConfig, ...newAppState});

    useMessageReceiveListener("setup", (merchantConfig: MerchantConfigModel)=>{

        // validate merchantConfig data
        if(!merchantConfig || Object.keys(merchantConfig).length === 0)
            setCurrentView(failedPage(localeTexts.add_configuration));

        // Validate Business SubscriptionKey (Primary or Secondary) Exists
        else if(!merchantConfig.apiKey && !APIs.isDevEnv())
            setCurrentView(failedPage(localeTexts.add_apikey));

        // Validate Business Id Exists
        else if(!merchantConfig.businessId)
            setCurrentView(failedPage(localeTexts.add_business_id));

        // Validate Business Id
        else if(!merchantConfig.business || !merchantConfig.business.id)
            setCurrentView(failedPage(localeTexts.invalid_business_id));

        // Validate Business Subscription Exists
        else if(!merchantConfig.business || !merchantConfig.business.id)
            setCurrentView(failedPage(localeTexts.invalid_business_id));

        // Validate Business SubscriptionKey (Primary or Secondary) Exists
        else if(!merchantConfig.business.subscriptionPrimaryKey  && !APIs.isDevEnv())
            setCurrentView(failedPage(localeTexts.subscription_not_found));

        // Validate Business Status
        else if(
            merchantConfig.business.status.toLowerCase() === "disabled" ||
            merchantConfig.business.status.toLowerCase() === "paused"
        ){
            setCurrentView(failedPage(localeTexts.inactive_business));
        }

        // Validate Customer Email
        else if(!merchantConfig.email)
            setCurrentView(failedPage(localeTexts.add_email));

        // Validate Amount
        else if(!merchantConfig.amount || merchantConfig.amount <= 0)
            setCurrentView(failedPage(localeTexts.add_amount));

        else{
            setMerchantConfig(merchantConfig);
            setCurrentView(children)
        }

        // Render Popup Visible on the main screen.
        SendEventMessage("onInitializingCompleted");

        // Freeze Configuration from Modification
        Object.freeze(merchantConfig);
        APIs.apiKey = merchantConfig.apiKey;
        APIs.apimBaseUrl = merchantConfig.business?.subscriptionUrl?.toLowerCase();
    }

    , merchantConfig)



    return (
        <AppSetupContext.Provider value={{
                merchantConfig, // readonly
                appConfig, updateAppConfig, // for managing app state
                currentView, setCurrentView, // for managing app current page
            }}>
            {currentView}
        </AppSetupContext.Provider>
    )
}
