import React from 'react';
import CardIcon from '../components/icon/CardIcon';
import LibraryIcon from '../components/icon/LibraryIcon';
import BankTransfer from '../pages/views/BankTransfer';
import PaymentCard from '../pages/views/PaymentCard';
import HashIcon from '../components/icon/HashIcon';
import AccountNumber from '../pages/views/AccountNumber';
import QRIcon from '../components/icon/QRIcon';
import NQRCode from '../pages/views/NQRCode';
import PhoneIcon from '../components/icon/PhoneIcon';
import PayByPhoneNumber from '../pages/views/PayByPhoneNumber';

export default function PaymentMethods(
  optionalFilterByIds: any = null
): Array<PaymentMethod> {
  let items = [
    {
      id: 1,
      link: '/paymentCard',
      title: 'Pay with Card',
      icon: <CardIcon />,
      view: <PaymentCard />,
    },

    {
      id: 3,
      link: '/bankDetails',
      title: 'Pay with Bank Details',
      icon: <HashIcon />,
      view: <AccountNumber />,
    },

    {
      id: 2,
      link: '/bankTransfer',
      title: 'Pay with Bank Transfer',
      icon: <LibraryIcon />,
      view: <BankTransfer />,
    },

    // {
    //   id: 4,
    //   link: '/qrCode',
    //   title: 'Pay with QR Code',
    //   icon: <QRIcon />,
    //   view: <NQRCode />,
    // },
    {
      id: 5,
      link: '/payByPhoneNumber',
      title: 'Pay with Phone Number',
      icon: <PhoneIcon />,
      view: <PayByPhoneNumber />,
    },
  ];

  return !optionalFilterByIds || optionalFilterByIds.includes('*')
    ? items
    : items.filter((value) =>
        optionalFilterByIds.split(',').includes(value.id.toString())
      );
}

export class PaymentMethod {
  id: number = 0;
  link: string = '';
  title: string = '';
  icon: any = null;
  view: any = null;
}
