import BankTransferResponseModel from './BankTransfer/BankTransferResponseModel';
import { PaymentMethod } from './PaymentMethods';
import CardResponseModel from './PaymentCard/CardResponseModel';
import OtpSendResponseModel from './AccountNumber/OtpSendResponseModel';
import NQRCodeResponseModel from './NQRCode/NQRCodeResponseModel';
import PayByPhoneNumberResponseModel from './PayByPhoneNumber/PayByPhoneNumberResponseModel';
export class MerchantConfigModel {
  // APIM Access info
  apiKey = ''; // Business Subscription Key (Primary or Secondary Key)

  // Business Info
  businessId = ''; // Business Id
  amount = 0;
  currency = 'NGN';
  orderId = '';
  description = '';
  businessName = '';
  business = {
    id: '',
    name: '',
    status: '',
    statusReason: '',
    paymentMethodIds: '',
    subscriptionUrl: '',
    subscriptionPrimaryKey: '',
    merchant: {},
  };

  // Customer Info
  email = '';
  firstName = '';
  lastName = '';
  phone = '';
  metadata: object | string = '';

  // Others
  transactionCharge = 0;
  customButtonId = null;
  enabledOnTransactionCallback: boolean = false;
}

export class AppConfigModel {
  isTransactionValidated: boolean = false;

  paymentSuccess?: boolean = false;

  currentPaymentMethod: PaymentMethod = new PaymentMethod(); // state of current payment

  bankTransferResponse?: BankTransferResponseModel;
  virtualAccountExpiryTime = 30;
  virtualAccountAutoLoadError = '';

  paymentCardResponse?: CardResponseModel;

  nqrCodeResponse?: NQRCodeResponseModel;
  nqrCodeExpiryTime = 25;
  nqrCodeAutoLoadError = '';
  paybyPhoneNumberAutoLoadError = '';
  otpSendResponse?: OtpSendResponseModel;
  paybyPhoneNumberResponse?: PayByPhoneNumberResponseModel;
}

export class AppSetupModel {
  // merchant default settings
  merchantConfig: MerchantConfigModel = new MerchantConfigModel();
  setMerchantConfig?: Function = () => null;

  // app preference (e.g virtualAccount Response Model)
  appConfig: AppConfigModel = new AppConfigModel();
  updateAppConfig: Function = () => null;
  setAppConfig?: Function = () => null;

  // active view
  currentView: any = null;
  setCurrentView: Function = () => null;
}
