export default function CancelIcon2() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="h-11 w-11 inline"
			viewBox="0 0 30 31"
			fill="currentColor"
		>
			<path
				fillRule="evenodd"
				d="M14.5833 30.0833C6.52896 30.0833 0 23.5543 0 15.5C0 7.44559 6.52896 0.916626 14.5833 0.916626C22.6377 0.916626 29.1667 7.44559 29.1667 15.5C29.1667 23.5543 22.6377 30.0833 14.5833 30.0833ZM14.5833 13.4379L10.4592 9.31225L8.39563 11.3758L12.5213 15.5L8.39563 19.6241L10.4592 21.6877L14.5833 17.562L18.7075 21.6877L20.771 19.6241L16.6454 15.5L20.771 11.3758L18.7075 9.31225L14.5833 13.4379Z"
				// fill="#FF0000"
				clipRule="evenodd"
			/>
		</svg>
	);
}
