import React, { useState } from "react";
import MainContainer from "../layout/MainContainer";
import { useAppSetup } from "../../components/providers/AppSetupProvider";
import { formatNumberOnly } from "../../helper/Formater";
import HttpRequest from "../../helper/HttpRequest";
import { AppSetupModel } from "../../types/AppSetupModel";
import { SendTransactionEvent } from "../../helper/ParentPage";
import { useNotification } from "../../components/providers/NotificationProvider";
import LoadingButton from "../../components/LoadingButton";
import OtpSendRequestModel from "../../types/AccountNumber/OtpSendRequestModel";
import ApiResponse from "../../types/ApiResponse";
import OtpValidateRequestModel from "../../types/AccountNumber/OtpValidateRequestModel";
import InfoBoxSuccessfulTransaction from "../../components/InfoBoxSuccessfulTransaction";
import { APIs } from "../../configs/apis";

export default function AccountNumber() {
	let { merchantConfig, appConfig, updateAppConfig }: AppSetupModel =
		useAppSetup();
	let [buttonLoader, startButtonLoader] = useState(false);
	let notification = useNotification();

	let [otpSendRequest, setOtpSendRequest] = useState(
		new OtpSendRequestModel(merchantConfig)
	);
	let [otpValidateRequest, setOtpValidateRequest] = useState(
		new OtpValidateRequestModel()
	);
	const [activeButton, setActiveButton] = useState<boolean>(false);

	let makeCardRequest = (
		url: any,
		requestBody: any,
		successCallback: any
	): Promise<ApiResponse> => {
		// request to backend
		return HttpRequest("POST", url, requestBody)
			.then((response) => {
				successCallback(response);
				return response;
			})
			.catch((err) => {
				console.log("Error: ", err);
				notification.notify(err.message);
				return err;
			})
			.finally();
	};

	/**
	 * Send OTP to User
	 */
	let sendOtp = () => {
		// validate
		if (otpSendRequest.accountNumber.length < 10)
			return notification.notify("Please input a valid account number");

		startButtonLoader(true);
		return makeCardRequest(
			APIs.AccountNumber.sendOtp(),
			otpSendRequest,
			(response: ApiResponse) => {
				if (!response.status) return notification.notify(response.message);
				updateAppConfig({ otpSendResponse: response.data });
			}
		).finally(() => startButtonLoader(false));
	};

	/**
	 * Validate OTP
	 */
	let validateOtp = () => {
		startButtonLoader(true);
		return makeCardRequest(
			APIs.AccountNumber.validateOtp(),
			otpValidateRequest,
			(response: ApiResponse) => {
				if (!response.status) return notification.notify(response.message);

				// Send Transaction to frontend and Set isTransactionValidated to true
				SendTransactionEvent(response);
				updateAppConfig({ isTransactionValidated: true });
			}
		).finally(() => startButtonLoader(false));
	};

	const handleButton = () => {
		setActiveButton(true);
		sendOtp();
	};

	return (
		<MainContainer active="/bankDetails" activeButton={activeButton}>
			{
				/*
				 *  Transaction Successful
				 */
				appConfig.isTransactionValidated ? (
					<InfoBoxSuccessfulTransaction
						transactionId={appConfig.otpSendResponse?.transactionId}
					/>
				) : /*
				 *  Validate OTP
				 */
				appConfig.otpSendResponse?.transactionId != null ? (
					<>
						<div className="mt-3">
							<span>{appConfig.otpSendResponse.message}</span>
						</div>
						<form action="#">
							<div className="mt-2 p-3 bg-gray-100 rounded">
								<div>
									<label htmlFor="" className="text-primary-dark font-bold">
										Input OTP
									</label>
									<div className="mt-2">
										<input
											type="tel"
											maxLength={10}
											required={true}
											className="customer-input font-mono"
											placeholder="input OTP"
											value={otpValidateRequest.otp}
											onChange={(event) => {
												setOtpValidateRequest({
													...otpValidateRequest,
													transactionId:
														appConfig.otpSendResponse?.transactionId,
													otp: event.target.value,
												});
											}}
										/>
									</div>
								</div>
							</div>
						</form>
						<LoadingButton
							isLoading={buttonLoader}
							defaultText={"Validate Otp"}
							loadingText="Validating otp..."
							onClick={() => !buttonLoader && validateOtp()}
						/>
					</>
				) : (
					/*
					 *  Send OTP
					 */
					<>
						<div className="mt-3">
							<span>Enter your account number details below</span>
						</div>
						<form action="#">
							<div className="mt-2 p-3 bg-gray-100 rounded">
								<div>
									<label htmlFor="" className="text-primary-dark font-bold">
										Account Number
									</label>
									<div className="mt-2">
										<input
											type="tel"
											maxLength={10}
											required={true}
											className="customer-input font-mono"
											placeholder="10 digits Account number"
											value={otpSendRequest.accountNumber}
											onChange={(event) => {
												setOtpSendRequest({
													...otpSendRequest,
													accountNumber: (event.target.value = formatNumberOnly(
														event.target.value
													)),
												});
											}}
										/>
									</div>
								</div>

								<div className="mt-4">
									<label htmlFor="" className="text-primary-dark font-bold">
										Select Bank
									</label>
									<div className="mt-2">
										<select
											className="customer-select font-mono"
											placeholder=""
											onChange={(event) => {
												setOtpSendRequest({
													...otpSendRequest,
													bankCode: event.target.value,
												});
											}}
										>
											<option value="035">Wema Bank</option>
											<option value="035">ALAT by Wema</option>
										</select>
									</div>
								</div>
							</div>
						</form>
						<LoadingButton
							isLoading={buttonLoader}
							defaultText={"Send Otp"}
							loadingText="Sending otp..."
							onClick={() => !buttonLoader && handleButton()}
						/>
					</>
				)
			}
		</MainContainer>
	);
}
