import React, { useEffect, useState } from "react";
import MainContainer from "../layout/MainContainer";
import VirtualAccountExpired from "../../components/VirtualAccountExpired";
import copyIcon from "../../img/copy.svg";
import { useAppSetup } from "../../components/providers/AppSetupProvider";
import { useLoader } from "../../components/providers/LoaderProvider";
import { formatCurrency } from "../../helper/Formater";
import HttpRequest from "../../helper/HttpRequest";
import { APIs } from "../../configs/apis";
import { AppSetupModel } from "../../types/AppSetupModel";
import InfoBox from "../../components/InfoBox";
import {
	SendCloseDialogEvent,
	SendTransactionEvent,
} from "../../helper/ParentPage";
import { useNotification } from "../../components/providers/NotificationProvider";
import BankTransferRequestModel from "../../types/BankTransfer/BankTransferRequestModel";
import LoadingButton from "../../components/LoadingButton";
import ApiResponse from "../../types/ApiResponse";
import HttpEventSource from "../../helper/HttpEventSource";
import ValidateTransactionAndCloseDialog from "../../components/ValidateTransactionAndCloseDialog";
import InfoBoxSuccessfulTransaction from "../../components/InfoBoxSuccessfulTransaction";
import InfoBoxPendingTransaction from "../../components/InfoBoxPendingTransaction";
import InfoBoxFailedTransaction from "../../components/InfoBoxFailedTransaction";

export default function BankTransfer() {
	const EXPIRE_MINUTE_TIMEOUT = 30;
	let {
		merchantConfig,
		appConfig,
		updateAppConfig,
		setCurrentView,
	}: AppSetupModel = useAppSetup();
	let { isLoading, showLoader, renderLoader }: any = useLoader();
	let [buttonLoader, startButtonLoader] = useState(false);
	let [isPaymentPendingVisible, setIsPaymentPendingVisible] = useState(false);
	const [activeButton, setActiveButton] = useState<boolean>(false);
	const [transactionStatus, setTransactionStatus] = useState<boolean>(false);
	let notification = useNotification();

	let requestObject: BankTransferRequestModel = new BankTransferRequestModel(
		merchantConfig
	);

	/**
	 * Account number expire countdown
	 */
	useEffect(() => {
		// Fetch next virtual account
		if (
			!appConfig?.bankTransferResponse &&
			!isLoading &&
			!appConfig?.virtualAccountAutoLoadError
		) {
			fetchNewVirtualAccount();
		}

		// Track Virtual Account Expiry
		let watch = setInterval(() => {
			if (
				!isLoading &&
				appConfig?.bankTransferResponse &&
				appConfig?.virtualAccountExpiryTime > 0
			)
				// @ts-ignore
				updateAppConfig({
					virtualAccountExpiryTime: appConfig?.virtualAccountExpiryTime - 1,
				});
		}, 60000);

		return () => clearTimeout(watch);
	});

	useEffect(() => {
		if (transactionStatus) {
			setIsPaymentPendingVisible(false);
		}
	}, [transactionStatus])

	/**
	 * Copy account number to clipboard
	 */
	let copyToClipboard = (virtualBankAccountNumber: any) => {
		// @ts-ignore
		if (window.clipboardData && window.clipboardData.setData) {
			// @ts-ignore
			window.clipboardData.setData("Text", virtualBankAccountNumber); // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		} else if (
			document.queryCommandSupported &&
			document.queryCommandSupported("copy")
		) {
			let textarea = document.createElement("textarea");
			textarea.textContent = virtualBankAccountNumber;
			textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
			document.body.appendChild(textarea);
			textarea.select();
			try {
				document.execCommand("copy"); // Security exception may be thrown by some browsers.
			} catch (ex) {
				notification.notify("Failed to copy " + virtualBankAccountNumber);
				return false;
			} finally {
				document.body.removeChild(textarea);
			}
		}
		notification.notify(virtualBankAccountNumber + " Copied!");
	};

	/**
	 * Check if transaction is available on payment made
	 */
	const confirmPayment = () => {
		startButtonLoader(true);
		let transactionId = appConfig.bankTransferResponse?.transactionId;
		if (!transactionId) return notification.notify("No transaction found!");

		// Validate Payment
		return HttpRequest(
			"GET",
			APIs.BankTransfer.validatePayment().replace(
				"{transactionId}",
				transactionId
			),
			null
		)
			.then((response) => {
				SendTransactionEvent(response);

				if (response?.data?.status === "Failed") {
					updateAppConfig({
						isTransactionValidated: true,
						paymentSuccess: false,
					});
				}
				if (response?.data?.status === "completed") {
					updateAppConfig({
						isTransactionValidated: true,
						paymentSuccess: true,
					});
				} else {
					updateAppConfig({
						isTransactionValidated: true,
						paymentSuccess: false,
					});
				}
			})
			.catch((err: ApiResponse) => {
				console.log("Error: ", err);
				if (err.statusCode === 404) setIsPaymentPendingVisible(true);
				else return notification.notify(err.message);
			})
			.finally(() => startButtonLoader(false));
	};

	const handleButton = () => {
		setActiveButton(true);
		confirmPayment();
	};

	/**
	 * Render Appropriate View
	 *  - virtual information (Default)
	 *  - virtual information expired (after some minutes)
	 *  - for Payment is pending (When I've Made payment is been clicked)
	 *  - for Payment is Success (When I've Made payment is been clicked)
	 */
	const BankTransferDetails = () => {
		return isLoading || !merchantConfig ? (
			renderLoader("Please wait...")
		) : appConfig?.virtualAccountAutoLoadError ? (
			<InfoBox buttonText="Try Again" onClick={fetchNewVirtualAccount}>
				{appConfig?.virtualAccountAutoLoadError}
			</InfoBox>
		) : /*
		 *  Transaction Pending
		 */
		isPaymentPendingVisible ? (
			<InfoBoxPendingTransaction
				transactionId={appConfig.bankTransferResponse?.transactionId}
				onConfirmPayment={confirmPayment}
				onHide={() => setIsPaymentPendingVisible(false)}
			/>
		) : /*
		 *  Transaction Successful
		 */
		appConfig.isTransactionValidated && appConfig?.paymentSuccess ? (
			<InfoBoxSuccessfulTransaction
				transactionId={appConfig.bankTransferResponse?.transactionId}
			/>
		) : /*
		 * Transaction Failed
		 */
		appConfig.isTransactionValidated && !appConfig.paymentSuccess ? (
			<InfoBoxFailedTransaction
				transactionId={appConfig.bankTransferResponse?.transactionId}
				errorMessage={
					"Your transaction isn't processed because you transferred an amount that is different from the expected amount. You will get a refund of the money"
				}
			/>
		) : /*
		 *  Is Virtual Account Expired
		 */
		// @ts-ignore
		appConfig?.virtualAccountExpiryTime <= 0 ? (
			<VirtualAccountExpired onClick={fetchNewVirtualAccount} />
		) : (
			/*
			 *  Virtual Account Information
			 */
			<div>
				<p className="mt-4 text-gray-500 text-center bg-gray-100 rounded py-2 px-3">
					<span>
						Open your bank app, USSD or Web Banking platform to make a transfer
						to the account provided below.
					</span>
				</p>
				<div className="text-center mt-2">
					<span className="text-gray-500">
						Transfer{" "}
						<strong>
							{formatCurrency(merchantConfig.amount, merchantConfig.currency)}
						</strong>{" "}
						to
					</span>
					<span className="block mt-1 text-base">
						{" "}
						{appConfig?.bankTransferResponse?.virtualBankAccountName}{" "}
					</span>
				</div>
				<div className="my-2 px-5 py-5  bg-gray-100 rounded text-center">
					<h3 className="text-lg">Wema Bank</h3>
					<h1 className="font-bold text-2xl mt-1">
						{appConfig.bankTransferResponse?.virtualBankAccountNumber} &nbsp;
						<button
							onClick={() =>
								copyToClipboard(
									appConfig.bankTransferResponse?.virtualBankAccountNumber
								)
							}
						>
							<img src={copyIcon} alt="" className="inline" />
						</button>
					</h1>
					<p className="mt-4 text-gray-500">
						Use this account for this transaction only. Account expires in{" "}
						{appConfig.virtualAccountExpiryTime} minutes
					</p>
				</div>

				<LoadingButton
					isLoading={buttonLoader}
					defaultText="I've made payment"
					loadingText="Validating payment..."
					onClick={() => !buttonLoader && handleButton()}
				/>
			</div>
		);
	};

	/**
	 * Generate Virtual Account and setup Event Source to listen to transaction
	 */
	const fetchNewVirtualAccount = () => {
		if (!merchantConfig) return;
		showLoader(true);

		// request to backend
		HttpRequest(
			"POST",
			APIs.BankTransfer.generateVirtualAccount(),
			requestObject
		)
			.then((response) => {
				updateAppConfig({
					bankTransferResponse: response.data,
					virtualAccountAutoLoadError: "",
					virtualAccountExpiryTime: EXPIRE_MINUTE_TIMEOUT,
				});

				// Subscribe to payment complete update
				HttpEventSource(
					`${APIs.BankTransfer.notification()}&transactionId=${
						response.data.transactionId
					}`,
					(data: ApiResponse) => {
						if (merchantConfig.enabledOnTransactionCallback)
							SendTransactionEvent(data);
						if (data?.data?.status === "Failed") {
							updateAppConfig({
								isTransactionValidated: true,
								paymentSuccess: false,
							});
						}
						if (data?.data?.status === "completed") {
							setTransactionStatus(true)
							updateAppConfig({
								isTransactionValidated: true,
								paymentSuccess: true,
							});
						} else {
							updateAppConfig({
								isTransactionValidated: true,
								paymentSuccess: false,
							});
						}
					}
				);
			})
			.catch((err) => {
				updateAppConfig({
					virtualAccountAutoLoadError: err.message,
				});
			})
			.finally(() => showLoader(false));
	};

	/**
	 * Try to validate payment if successful and close dialog
	 */
	let validatePaymentAndCloseDialog = () => {
		if (appConfig.isTransactionValidated) return SendCloseDialogEvent();
		setCurrentView(<ValidateTransactionAndCloseDialog />);
	};

	return (
		<MainContainer
			active="/bankTransfer"
			activeButton={activeButton}
			onClose={validatePaymentAndCloseDialog}
		>
			<BankTransferDetails />
		</MainContainer>
	);
}
