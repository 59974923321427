import AbstractResponseModel from "../AbstractResponseModel";

export default class CardResponseModel extends AbstractResponseModel{

    redirectHtml = ""

    gatewayRecommendation = ""

    orderId = ""

}



