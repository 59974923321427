
export default function VirtualAccountExpired({onClick}: any){
    return (
        <div className="py-5 text-center">

            <span className="mt-4 text-yellow-500 block">
                <svg  className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
            </span>

            <h2 className="text-lg mt-1">Account Expired</h2>

            <button className={"mt-4 btn btn-primary w-11/12 py-2 px-3"} onClick={onClick}> Try Again </button>
            <button className={"mt-2 btn btn-primary w-11/12 py-2 px-3"}> I already sent the money </button>

        </div>
    )
}
