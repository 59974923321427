import {APIs} from "../configs/apis";

export default function HttpEventSource(url: string, callback: Function): void{

    // console.log('Listening to event on', url)


    // OkHttpClient okClient = new OkHttpClient();
    // Request request = new Request.Builder().url(path).addHeader("Accept", "text/event-stream").build();
    // EventSource.Factory factory = EventSources.createFactory(okClient);
    // EventSource sseEventSource = factory.newEventSource(request, EVENT_SOURCE_LISTENER);

    let successEventSource = new EventSource(`${url}&subscription-key=${APIs.apiKey}`);

    // if connection is active
    //successEventSource.onopen = (event: any) => console.log('Event Source Opened', url, event);

    // if nothing is found
    // successEventSource.onerror = (event: any) => console.log('Event Source Error', url, event);

    // Message
    successEventSource.onmessage = function (e) {
        // console.log("Event Source Received Message RAW", e.data);

        let data = JSON.parse(e.data);
        if(data.status)
            callback(data)

        // console.log("Event Source Received Message JSON", data);
    }
}
