const useMacDevice = () => {
  // Check if the browser is Safari based on user agent string
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // Check if Safari supports the `WebKitAppearance` property
  const hasSafariAppearance = 'WebkitAppearance' in document.documentElement.style;
  // If Safari, and the `WebKitAppearance` property is not supported,
  // then the browser is not a Mac device.
  // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-appearance
  // https://developer.mozilla.org/en-US/docs/Web/CSS/WebkitAppearance
  // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html#//apple_ref/doc/uid/TP40006523-CH1-SW8
  return (isSafari && hasSafariAppearance);
}

export default useMacDevice