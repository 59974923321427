import React, { useEffect, useMemo, useRef, useState } from "react";
import MainContainer from "../layout/MainContainer";
import { useAppSetup } from "../../components/providers/AppSetupProvider";
import HttpRequest from "../../helper/HttpRequest";
import { AppConfigModel, AppSetupModel } from "../../types/AppSetupModel";
import { SendTransactionEvent } from "../../helper/ParentPage";
import { useNotification } from "../../components/providers/NotificationProvider";
import LoadingButton from "../../components/LoadingButton";
import { APIs } from "../../configs/apis";
import PayByPhoneNumberModel from "../../types/PayByPhoneNumber/PayByPhoneNumberRequestModel";
import PayByPhoneNumberRequestModel from "../../types/PayByPhoneNumber/PayByPhoneNumberRequestModel";
import { useLoader } from "../../components/providers/LoaderProvider";
import InfoBoxPendingTransaction from "../../components/InfoBoxPendingTransaction";
import HttpEventSource from "../../helper/HttpEventSource";
import ApiResponse from "../../types/ApiResponse";
import InfoBoxFailedTransaction from "../../components/InfoBoxFailedTransaction";
import InfoBoxSuccessfulTransaction from "../../components/InfoBoxSuccessfulTransaction";
import InfoBox from "../../components/InfoBox";
import { formatCurrency } from "../../helper/Formater";
import { __ } from "../../configs/localizations";

export default function PayByPhoneNumber() {
	const PHONE_NUMBER_LENGTH = 11;
	let [buttonLoader, startButtonLoader] = useState(false);
	let notification = useNotification();
	let [isPaymentPendingVisible, setIsPaymentPendingVisible] = useState(false);
	let { merchantConfig, appConfig, updateAppConfig }: AppSetupModel =
		useAppSetup();
	let { isLoading, showLoader, renderLoader }: any = useLoader();
	let requestObject: PayByPhoneNumberRequestModel =
		new PayByPhoneNumberRequestModel(merchantConfig);
	let [payByPhoneNumber, setpayByPhoneNumber] = useState(
		new PayByPhoneNumberModel(merchantConfig)
	);
	const [activeButton, setActiveButton] = useState<boolean>(false);

	useEffect(() => {
		// Check if paybyPhoneNumber is falsy, isLoading is false, and there is no paybyPhoneNumberAutoLoadError
		if (payByPhoneNumber.phoneNumber?.length !== 11) return;
		if (
			!appConfig?.paybyPhoneNumberResponse &&
			!isLoading &&
			!appConfig?.paybyPhoneNumberAutoLoadError &&
			payByPhoneNumber.phoneNumber &&
			payByPhoneNumber.phoneNumber.toString().length === 11
		) {
			// Initialize payment
			initializePayment();
		}
	}, [payByPhoneNumber.phoneNumber]); // Empty dependency array to ensure it runs once after the initial render

	const customRedColor = {
		color: "#A90836",
	};
	const initializePayment = () => {
		return new Promise<string>((resolve, reject) => {
			if (!merchantConfig) {
				// Do not proceed if merchantConfig is falsy
				reject(new Error("Merchant configuration not available."));
				return;
			}

			let phoneNumber: string;

			if (typeof payByPhoneNumber.phoneNumber === "string") {
				// If phoneNumber is already a string, use it as is
				phoneNumber = payByPhoneNumber.phoneNumber;
			} else {
				// If phoneNumber is a number, convert it to a string
				phoneNumber = payByPhoneNumber.phoneNumber;
			}

			// Check if phoneNumber is 11 digits long
			if (phoneNumber.length !== 11) {
				// Do not proceed if phone number is not 11 digits long
				reject(new Error("Invalid phone number."));
				return;
			}
			startButtonLoader(true);
			renderLoader(true);
			const requestObjectWithPhoneNumber = {
				...requestObject,
				channel: "Phone Number",
				phoneNumber: payByPhoneNumber.phoneNumber,
			};

			// request to backend
			HttpRequest(
				"POST",
				APIs.PaybyPhoneNumber.initialize(),
				requestObjectWithPhoneNumber
			)
				.then((response) => {
					updateAppConfig({
						paybyPhoneNumberResponse: response.data,
						paybyPhoneNumberAutoLoadError: "",
					});
				})
				.catch((err) => {
					updateAppConfig({
						paybyPhoneNumberAutoLoadError: err.message,
					});
					reject(err);
				})
				.finally(() => {
					showLoader(false);
					startButtonLoader(false);
				});
		});
	};

	const makePayment = () => {
		// startButtonLoader(true);
		if (!merchantConfig) return;

		// validation
		if (
			payByPhoneNumber.phoneNumber?.length < PHONE_NUMBER_LENGTH
		)
			return notification.notify(__.phone_number_invalid);

		setIsPaymentPendingVisible(true);
		// request to backend
		HttpRequest(
			"POST",
			APIs.PaybyPhoneNumber.complete(),
			appConfig?.paybyPhoneNumberResponse
		)
			.then((response) => {
				SendTransactionEvent(response);
				updateAppConfig({
					paybyPhoneNumberResponse: response.data,
					paybyPhoneNumberAutoLoadError: "",
				});
				HttpEventSource(
					`${APIs.PaybyPhoneNumber.notification()}&transactionId=${
						response?.data?.id
					}`,
					(data: ApiResponse) => {
						setIsPaymentPendingVisible(false);
						updateAppConfig({
							isTransactionValidated: true,
							paymentSuccess: true,
						});
					}
				);
			})
			.catch((err) => {
				setIsPaymentPendingVisible(false);
				updateAppConfig({
					isTransactionValidated: true,
					paymentSuccess: false,
				});
			})
			.finally(() => startButtonLoader(false));
	};
	const notifyusers = () => {
		let transactionId = appConfig.paybyPhoneNumberResponse?.transactionId;

		if (!transactionId) return notification.notify(__.no_transaction_found);

		// Construct the notification API endpoint with transactionId as a query parameter
		const notificationEndpoint = `${APIs.PaybyPhoneNumber.notification()}&transactionId=${transactionId}`;

		HttpRequest("GET", notificationEndpoint)
			.then((response) => {

				if (response?.data?.status === "Failed") {
					updateAppConfig({
						isTransactionValidated: true,
						paymentSuccess: false,
					});
				} else if (response?.data?.status === "completed") {
					updateAppConfig({
						isTransactionValidated: true,
						paymentSuccess: true,
					});
				} else {
					updateAppConfig({
						isTransactionValidated: true,
						paymentSuccess: false,
					});
				}
			})
			.catch((error) => {
				console.error("Notification request failed:", error);
				updateAppConfig({
					isTransactionValidated: true,
					paymentSuccess: false,
				});
			});
	};

	const handleButton = () => {
		setActiveButton(true);
		makePayment();
	};

	return (
		<MainContainer active="/payByPhoneNumber" activeButton={activeButton}>
			{isLoading ? (
				renderLoader("Please wait...")
			) : appConfig?.paybyPhoneNumberAutoLoadError ? (
				<InfoBox buttonText="Try Again" onClick={makePayment}>
					{appConfig?.paybyPhoneNumberAutoLoadError}
				</InfoBox>
			) : isPaymentPendingVisible ? (
				<InfoBoxPendingTransaction
					transactionId={appConfig.paybyPhoneNumberResponse?.transactionId}
					onConfirmPayment={notifyusers}
					onHide={() => setIsPaymentPendingVisible(false)}
				/>
			) : appConfig.isTransactionValidated && !appConfig.paymentSuccess ? (
				<InfoBoxFailedTransaction
					transactionId={appConfig.paybyPhoneNumberResponse?.transactionId}
					errorMessage={
						"We are unable to process your transaction right now. Please try again later."
					}
				/>
			) : appConfig.isTransactionValidated && appConfig?.paymentSuccess ? (
				<InfoBoxSuccessfulTransaction
					transactionId={appConfig.paybyPhoneNumberResponse?.transactionId}
				/>
			) : (
				<div>
					<div className="mt-3">
						<span>Enter your phone number below to make payment</span>
					</div>
					<form action="#">
						<div className="mt-2 p-3 bg-gray-100 rounded">
							<div>
								<label htmlFor="" className="text-primary-dark font-bold">
									Phone Number
								</label>
								<div className="mt-2">
									<input
										type="tel"
										maxLength={11}
										required={true}
										className="customer-input font-mono"
										placeholder="11 digits Phone number"
										value={payByPhoneNumber.phoneNumber}
										onChange={(event) => {
											const formattedPhoneNumber = event.target.value
												.replace(/[^\d-]+/g, "") // Remove non-numeric characters except dashes
												.replace(/-+/g, "-"); // Remove consecutive dashes
											if (event.target.value?.length !== 11) {
												updateAppConfig({
													paybyPhoneNumberResponse: null,
												});
											}
											setpayByPhoneNumber({
												...payByPhoneNumber,
												phoneNumber: formattedPhoneNumber,
											});
										}}
									/>
								</div>
								<span>
									You will be charged a fee of{" "}
									<span style={customRedColor}>N10.00 </span>for this
									transaction
								</span>
							</div>
						</div>
					</form>
					<LoadingButton
						isLoading={buttonLoader}
						defaultText={
							"Pay " +
							formatCurrency(merchantConfig?.amount, merchantConfig?.currency)
						}
						loadingText="Please wait..."
						disabled={
							!payByPhoneNumber.phoneNumber ||
							!appConfig?.paybyPhoneNumberResponse?.phoneNumber
						}
						onClick={() => !buttonLoader && handleButton()}
					/>
				</div>
			)}
		</MainContainer>
	);
}
