import React, {useContext, useState} from "react";
import LoaderModel from "../../types/LoaderModel";

const LoaderContext = React.createContext(new LoaderModel());

export function useLoader(): LoaderModel{
    return useContext(LoaderContext);
}




export function LoaderProvider({children}:any){
    let [isLoading, showLoader] = useState(false);

    function renderLoader(text = null){
        return (!isLoading)? null:(
            <div className="text-center p-6">
                <span className="animate-spin inline-block" style={{
                    border: "5px solid #f3f3f3",
                    borderRadius: "50%",
                    borderTop: "5px solid #BE185D",
                    width: "50px",
                    height: "50px"
                }} />

                {text?<div className="mt-4 text-gray-400 text-sm">{text}</div>: null }
            </div>
        )
    }

    return (
        <LoaderContext.Provider value={{ isLoading, showLoader, renderLoader }}>
            {children}
        </LoaderContext.Provider>
    );
}



