import ApiResponse from "../types/ApiResponse";
import {APIs} from "../configs/apis";

/**
 * HTTP Request to other server
 */
export default function HttpRequest(method = "GET", url: string, payload:any = null): Promise<ApiResponse>  {

    // console.log("Request Information", url, payload)

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Ocp-Apim-Subscription-Key': APIs.apiKey,
                'Ocp-Apim-Trace': 'true',
            },
            body: payload? JSON.stringify(payload): null,
        })
        .then(async response => ApiResponse.ensureModel(await response.json(), response.status, response.statusText) )
        .then(apiResponse =>{
            // console.log("Response", {[method + ":url"]: url, request: payload, response: apiResponse })
            if(apiResponse && apiResponse.status)
                resolve(apiResponse);
            else{
                console.error("Failed Response Message: " + apiResponse.message)
                reject(apiResponse);
            }
        }).catch((response)=>{
            console.error("Unable to make request to the server." + response)
            reject(new ApiResponse(null, `Unable to make request to the server. Reason (${response.message})`, false))
        });
    })
}

/**
 * HTTP Request to other server
 */
export function HttpNethoneRequest(method = "GET", url: string, payload:any = null): Promise<ApiResponse>  {

    // console.log("Request Information", url, payload)

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: payload? JSON.stringify(payload): null,
        })
        .then(async response => ApiResponse.ensureModel(await response.json(), response.status, response.statusText) )
        .then(apiResponse =>{
            // console.log("Response", {[method + ":url"]: url, request: payload, response: apiResponse })
            if(apiResponse && apiResponse.status)
                resolve(apiResponse);
            else{
                console.error("Failed Response Message: " + apiResponse.message)
                reject(apiResponse);
            }
        }).catch((response)=>{
            console.error("Unable to make request to the server." + response)
            reject(new ApiResponse(null, `Unable to make request to the server. Reason (${response.message})`, false))
        });
    })
}

