import {SendCloseDialogEvent, SendTransactionEvent} from "../helper/ParentPage";
import {APIs} from "../configs/apis";
import HttpRequest from "../helper/HttpRequest";
import BoxCenter from "./BoxCenter";
import React from "react";
import {useAppSetup} from "./providers/AppSetupProvider";


export default function ValidateTransactionAndCloseDialog(): any{
    let {appConfig, merchantConfig} = useAppSetup();

    let closeDialog = ()=>{
        SendCloseDialogEvent()
        return <></>
    }

    if(!merchantConfig.enabledOnTransactionCallback || appConfig.isTransactionValidated || !appConfig?.currentPaymentMethod)
        return closeDialog();

    let transactionUrl = null;

    // Payment Card
    if(appConfig.currentPaymentMethod.id === 1 && appConfig.paymentCardResponse?.transactionId)
        transactionUrl = APIs.PaymentCard.transaction().replace("{transactionId}", appConfig.paymentCardResponse?.transactionId);

    // Bank Transfer
    else if(appConfig.currentPaymentMethod.id === 2 && appConfig.bankTransferResponse?.transactionId)
        transactionUrl = APIs.BankTransfer.transaction().replace("{transactionId}", appConfig.bankTransferResponse?.transactionId);

    // NQR Code
    else if(appConfig.currentPaymentMethod.id === 4 && appConfig.nqrCodeResponse?.transactionId)
    transactionUrl = APIs.NQRCode.validatePayment().replace("{transactionId}", appConfig.nqrCodeResponse?.transactionId);

    if(!transactionUrl)
        return closeDialog();

    // Make Http request to fetch transaction info
    HttpRequest("GET", transactionUrl).then(data=>{
        if(data.status)
            SendTransactionEvent(data);
    }).finally(()=> closeDialog());

    return <BoxCenter>
        <div>
            <div className="text-center p-6 bg-white rounded">
                            <span className="animate-spin inline-block" style={{
                                border: "5px solid #f3f3f3",
                                borderRadius: "50%",
                                borderTop: "5px solid #BE185D",
                                width: "50px",
                                height: "50px"
                            }} />
                <div className="mt-4 text-gray-400 text-lg">Closing. Please wait...</div>
            </div>
        </div>
    </BoxCenter>
}
