export const formatCardNumber = (value) => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, "");

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter((group) => !!group).join(" ")
  );
};

export const formatCardExpiry = (value) => {
  const regex = /^(\d{0,2})(\d{0,2})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, "");

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2].filter((group) => !!group).join("/")
  );
};

export const formatNumberOnly = (value) => {
  return value.replace(/[^0-9]/g, "");
};

export const formatCurrency = (amount, currency = "NGN") => {
  //return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, currency + '&,');
  // amount = parseInt(amount);
  amount = amount.toFixed(2).replace(new RegExp("\\.0{" + 2 + "}"), "");
  return `${currency} ${amount}`;
  // return amount.toLocaleString("en-US", {
  //   style: "currency",
  //   currency: currency,
  // });
};

export function capitalizeWords(string) {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

export function ifEmptyOrNull(value, defaultValue = '') {
  return !value || value.trim().length <= 0 ? defaultValue : value;
}
