import './App.css';
import { useAppSetup } from "./components/providers/AppSetupProvider";
import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";
import PaymentMethods from "./types/PaymentMethods";
import React, { useEffect } from "react";
import { AppSetupModel } from "./types/AppSetupModel";


/**
 * FIGMA DESIGN https://www.figma.com/proto/4NQ86MMg6DMgnSiytks3hB/Payment-Gateway-Pluggin?node-id=62%3A190&scaling=min-zoom&page-id=62%3A138
 *
 */
function App({ props }: any) {
    let setupConfig: AppSetupModel = useAppSetup();
    let paymentMethodList = PaymentMethods(setupConfig.merchantConfig.business.paymentMethodIds);

    useEffect(() => {
        setupConfig.updateAppConfig({ currentPaymentMethod: paymentMethodList[0] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Router>
            <Switch>
                {
                    paymentMethodList.map(value => (
                        <Route path={value.link} key={value.id}>
                            {value.view}
                        </Route>
                    ))
                }

                <Route path="/">
                    {
                        paymentMethodList[0].view
                    }
                </Route>
            </Switch>
        </Router>
    )
}

export default App;
