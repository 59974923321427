export const  localeTexts = {
    page_not_found: <span>Setup configuration not found.<br/><small className="text-sm text-gray-400">This page cannot be used directly.</small></span>,
    add_configuration: <span>Please add the configuration data.</span>,
    add_email: <span>Please add the customer email.</span>,
    add_amount: <span>Unable to determine transaction amount.</span>,
    add_business_id: <span>Please provide the business Id.</span>,
    add_apikey: <span>Please provide the api key.</span>,
    subscription_not_found: <span>No subscription found on the Business!</span>,
    invalid_business_id: <span>Api key failed. <br/>Unable to validate api key.</span>,
    inactive_business: <span>Business is not Active. <br/>This Business is not enabled <br/>for receiving payment.</span>,

    card_invalid: "Please complete your card details",
    card_cannot_proceed: "Unable to proceed with this card.",

    payment_is_pending: "Payment is still pending. You can choose to close this dialog or try again. We will notify you either way once we confirm payment.",
    payment_successful: "Payment is successfully confirmed.",

    phone_number_invalid: "Please provide a valid phone number.",
    no_transaction_found: "No transaction found. Please try again.",

    error_occured: "An error occurred. Please try again.",
}

// Alias
export const __ = localeTexts;
