import { MerchantConfigModel } from './AppSetupModel';

export class CustomerModel {
  email = '';

  phone = '';

  firstName = '';

  lastName = '';

  metadata = '';
}

export default abstract class AbstractRequestModel {
  transactionId = '';

  amount = '';

  currency = 'NGN';

  orderId = '';

  businessId = '';

  businessName = '';

  description = '';
  phoneNumber = '';
  // channel = 'Phone Number';
  customer: CustomerModel = new CustomerModel();

  constructor(configModel: MerchantConfigModel) {
    this.businessId = configModel.business.id;

    this.businessName = configModel.businessName;

    this.orderId = configModel.orderId;

    this.amount = configModel.amount.toString();

    this.currency = configModel.currency;

    this.description = configModel.description;

    // Customer

    this.customer.email = configModel.email;

    this.customer.phone = configModel.phone;

    this.customer.firstName = configModel.firstName;

    this.customer.lastName = configModel.lastName;

    this.customer.metadata =
      typeof configModel.metadata === 'string'
        ? configModel.metadata
        : JSON.stringify(configModel.metadata);
  }
}
