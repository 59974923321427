import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ReportHandler} from "web-vitals";
import {LoaderProvider} from "./components/providers/LoaderProvider";
import {NotificationProvider} from "./components/providers/NotificationProvider";
import {AppSetupProvider} from "./components/providers/AppSetupProvider";


ReactDOM.render(
      <React.StrictMode>

          <AppSetupProvider>
              <LoaderProvider>
                  <NotificationProvider>

                    <App />

                  </NotificationProvider>
              </LoaderProvider>
          </AppSetupProvider>

      </React.StrictMode>,
      document.getElementById('root')
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const reportWebVitals = (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
        });
    }
};
reportWebVitals();

