import {useEffect} from "react";
import useMacDevice from "../hooks/useMacDevice";

/**
 * Send Message to Parent Page to call a function. (from Iframe Caller)
 */
export function SendEventMessage(type: string, data = null){
    window.parent.postMessage({type, data},'*');
}



/**
 * Receive Message from parent. (from Iframe Caller)
 */
export function useMessageReceiveListener(type: string, callBack: any, ...effectDependantList:any){
    const isSafari = useMacDevice();

    useEffect(() => {
        if(isSafari){
            SendEventMessage("onFrameReady")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        // setup listener
        // @ts-ignore
        let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        // @ts-ignore
        let eventHandler = window[eventMethod]("message", (event)=>{
            let eType = event.data.type;
            let eData = event.data.data;
            if(type === eType)
                callBack(eData);
        }, false);

        // clear event
        return ()=> eventHandler;

    }, [type, callBack, effectDependantList])
}




/**
 * Trigger Close Dialog Method from Parent
 */
export function SendCloseDialogEvent(){
    SendEventMessage("onCloseDialog");
}


/**
 * Payment made successfully. Send transaction record
 */
export function SendTransactionEvent(transactionResponse:any){
    SendEventMessage("onTransaction", transactionResponse);
}


