export default class ApiResponse {
    statusCode = 0
    status = false
    message = ""
    data: any

    constructor(data: any = null, message: string = "", status: boolean = false, statusCode = 0) {
        this.statusCode = statusCode === 0? this.statusCode: statusCode;
        this.data = data;
        this.message = message;
        this.status = status;
        return this;
    }

    static ensureModel(data:any, optionalStatusCode: number = 200, optionalMessage: string = ""): ApiResponse {
        if(data && data.hasOwnProperty("status") && data.hasOwnProperty("message") && data.hasOwnProperty("data"))
            return {...data, statusCode: (data.hasOwnProperty("statusCode")? data.statusCode: optionalStatusCode) };
        else
            return new ApiResponse(data,  optionalMessage,  optionalStatusCode >= 200 && optionalStatusCode < 400, optionalStatusCode);
    }

}


