import React from "react";
import SuccessIcon from "./icon/SuccessIcon";
import {SendCloseDialogEvent} from "../helper/ParentPage";
import {__} from "../configs/localizations";
import InfoBox from "./InfoBox";

export default function InfoBoxSuccessfulTransaction({transactionId}: {transactionId?:any}){

    let Content = ()=>(
        <InfoBox
            icon={<span className="text-green-500 block"><SuccessIcon /></span>}
            buttonText="Close"
            title="Transaction is successful"
            onClick={()=>SendCloseDialogEvent()}>{__.payment_successful}
            <div className="mt-0 text-green-600 text-xs"><dt><small>Ref: {transactionId}</small></dt> </div>
        </InfoBox>
    )

    return (
        <Content/>
    )
}
