export const BASE_API_URL = {
	DEV: {
		PaymentCard: "/payment-card/api/v1/paymentCard",
		BankTransfer: "/bank-transfer/api/v1/bankTransfer",
		AccountNumber: "/alatpay-bank-details/api/v1/accountNumber",
		NQRCode: "/alatpay-qr/api/v1",
		PaybyPhoneNumber: "/alatpay-phone-number/api/v1/phone-number-payment",
	},
	PREPROD: {
		PaymentCard: "/paymentcard/api/v1/paymentCard",
		BankTransfer: "/bank-transfer/api/v1/bankTransfer",
		AccountNumber: "/alatpayaccountnumber/api/v1/accountNumber",
		NQRCode: "/alatpayqr/api/v1",
		PaybyPhoneNumber: "/alatpay-phone-number/api/v1/phone-number-payment",
	},
	LIVE: {
		PaymentCard: "/paymentcard/api/v1/paymentCard",
		BankTransfer: "/bank-transfer/api/v1/bankTransfer",
		AccountNumber: "/alatpayaccountnumber/api/v1/accountNumber",
		NQRCode: "/alatpayqr/api/v1",
		PaybyPhoneNumber: "/alatpay-phone-number/api/v1/phone-number-payment",
	},
};

export const ENV = "LIVE"; // DEV / PREPROD / LIVE

export const APIs = {
	isDevEnv: () => {
		//return true;
		//return ((new URL(document.currentScript.src).origin).indexOf("//localhost:") > -1); //|| process.env.NODE_ENV !== 'development' //production
		return process.env.NODE_ENV === "development"; //production
	},

	apiKey: "", // Value is already been assigned inside AppSetupModels.ts
	apimBaseUrl: "", // Value is already been assigned inside AppSetupModels.ts
	getInitQuery: () => {
		return "ip=";
	},
	getApimBaseUrl: () => {
		// let baseServiceUrl = APIs.apimBaseUrl.split(".")[0] ?? process.env.REACT_APP_BASE_SERVICE_URL; // Prod/Staging Env. Breaking with dot so we can redirect the merchant to the appropriate APIM ServiceName
		// let baseServiceUrl = APIs.apimBaseUrl.split(".")[0] ?? 'https://WEMA-PAY-APIMGT'; // Prod/Staging Env. Breaking with dot so we can redirect the merchant to the appropriate APIM ServiceName
		// let baseServiceUrl = "https://lagos-alat-blueapi"; // Prod/Staging Env. Breaking with dot so we can redirect the merchant to the appropriate APIM ServiceName

		// return (baseServiceUrl + ".azure-api.net").toLowerCase(); // e.g https://alatpay.azure-api.net
		return "https://apibox.alatpay.ng";
	},

	// getCardMiddlewareUrl: () => {
	//   return "http://20.54.227.23/alatpaympgsmiddleware"
	// },
	/**
	 * Payment Card APIs
	 */
	PaymentCard: {
		baseUrl: () => APIs.getApimBaseUrl() + BASE_API_URL[ENV]["PaymentCard"],

		prepareUrl: (url) => APIs.PaymentCard.baseUrl() + url + '?' + APIs.getInitQuery(),
		// Master Card
		masterCard: {
			initialize: () => APIs.PaymentCard.prepareUrl("/mc/initialize"),
			authenticate: () => APIs.PaymentCard.prepareUrl("/mc/authenticate"),
		},
		transaction: () =>
			APIs.PaymentCard.prepareUrl("/transactions/{transactionId}"),
		notification: () =>
			APIs.PaymentCard.prepareUrl("/transactions/notification"),
	},

	/**
	 * Bank Transfer APIs
	 */
	BankTransfer: {
		baseUrl: () => APIs.getApimBaseUrl() + BASE_API_URL[ENV]["BankTransfer"],
		prepareUrl: (url) => APIs.BankTransfer.baseUrl() + url + '?' + APIs.getInitQuery(),
		generateVirtualAccount: () =>
			APIs.BankTransfer.prepareUrl("/virtualAccount"),
		validatePayment: () =>
			APIs.BankTransfer.prepareUrl("/transactions/{transactionId}"),
		transaction: () =>
			APIs.BankTransfer.prepareUrl("/transactions/{transactionId}"),
		notification: () =>
			APIs.BankTransfer.prepareUrl("/transactions/notification"),
	},

	/**
	 * Account Number APIs
	 */
	AccountNumber: {
		baseUrl: () => APIs.getApimBaseUrl() + BASE_API_URL[ENV]["AccountNumber"],
		prepareUrl: (url) => APIs.AccountNumber.baseUrl() + url + '?' + APIs.getInitQuery(),
		sendOtp: () => APIs.AccountNumber.prepareUrl("/sendOtp"),
		validateOtp: () => APIs.AccountNumber.prepareUrl("/validateAndPay"),
	},

	/**
	 * Card Inquiry APIs
	 */
	CardInquiryMiddleware: {
		baseUrl: () => APIs.getApimBaseUrl() + "/api/v1/alatpay-inquiry",
	},

	/**
	 * QR Code APIs
	 */
	NQRCode: {
		baseUrl: () => APIs.getApimBaseUrl() + BASE_API_URL[ENV]["NQRCode"],
		// baseUrl: () => "http://20.54.227.23/alatpayqr/api/v1",
		prepareUrl: (url) => APIs.NQRCode.baseUrl() + url + '?' + APIs.getInitQuery(),
		generate: () => APIs.NQRCode.prepareUrl("/alat-qr"),
		validatePayment: () =>
			APIs.NQRCode.prepareUrl("/qr/transactions/{transactionId}"),
		notification: () =>
			APIs.NQRCode.prepareUrl("/qr/transactions/qr-notification-stream"),
	},

	// Pay by phone Number

	PaybyPhoneNumber: {
		baseUrl: () =>
			APIs.getApimBaseUrl() + BASE_API_URL[ENV]["PaybyPhoneNumber"],

		prepareUrl: (url) => APIs.PaybyPhoneNumber.baseUrl() + url + '?' + APIs.getInitQuery(),

		initialize: () => APIs.PaybyPhoneNumber.prepareUrl("/initialize"),
		complete: () =>
			APIs.PaybyPhoneNumber.prepareUrl("/complete-phonenumber-payment"),

		transaction: () => APIs.PaybyPhoneNumber.prepareUrl("/transactions"),
		notification: () =>
			APIs.PaybyPhoneNumber.prepareUrl(
				"/transactions/phone-number-notification-stream"
			),
		transactionStatus: () => APIs.PaybyPhoneNumber.prepareUrl("/transactionId"),
	},
};
