import React, {useContext, useEffect, useState} from "react";
import NotificationModel from "../../types/NotificationModel";
import CancelIcon from "../icon/CancelIcon";

const NotificationContext = React.createContext(new NotificationModel());

export function useNotification(): NotificationModel{
    return useContext(NotificationContext);
}




export function NotificationProvider({children}:any){

    let [notifications, setNotifications] = useState<Array<string>>([]);

    let notify = (text: string)=> setNotifications( [...notifications, text]  )


    useEffect(()=>{
        let notificationRemovalWatch = setInterval(()=> {
            if(notifications.length <= 0) return;
            let notificationClone = [...notifications];
            notificationClone.shift();
            setNotifications(notificationClone)
        }, 6000)
        return ()=> clearInterval(notificationRemovalWatch);
    });


    let renderNotifier = ()=>{
        return (
            (notifications.length<=0 || !notifications[0])? null:
            <p className="absolute w-full left-0 bottom-0 justify-center p-3 bg-black text-gray-100 text-sm" style={{opacity: "0.9"}}>
                <span className="block-inline float-left">{ notifications[0] }</span>
                <button className="rounded float-right ml-2 text-gray-500
                    transition duration-500 ease-in-out
                    hover:text-gray-100" onClick={()=>setNotifications([])}><CancelIcon /></button>
            </p>
        );
    };

    return (
        <NotificationContext.Provider value={{ notify, renderNotifier }}>
            {children}
        </NotificationContext.Provider>
    );
}



