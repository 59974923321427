import AbstractRequestModel from "../AbstractRequestModel";

export default class CardRequestModel extends AbstractRequestModel{

    cardNumber = "" //5123450000000008

    cardMonth = "" //01

    cardYear = "" //39

    securityCode = "" //100

}
