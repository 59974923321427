import React from "react";


export default function LoadingButton({isLoading, defaultText, loadingText = "Please wait...", onClick }: any){
    return (
        <button onClick={
            (event)=> {
            event.preventDefault();
            onClick && onClick();
        }} className="bg-primary-dark text-white w-full rounded p-3 mt-2 font-bold">
            {
                isLoading? (
                    <div className="">
                        <span className="flex justify-center inline">
                            <span className="animate-spin rounded-full p-1 border-b-2 border-white mr-2" /> {loadingText}
                        </span>
                    </div>
                ): <div> { defaultText } </div>
            }
        </button>
    )
}
