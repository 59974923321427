import CancelIcon from "./icon/CancelIcon";
import {SendCloseDialogEvent} from "../helper/ParentPage";


export default function CloseDialog( {onClick = null }: any ){
    return (
        <div>
            <button className="text-red-500 absolute -right-7 -top-2" onClick={()=> onClick? onClick(): SendCloseDialogEvent()}>
                <CancelIcon />
            </button>
        </div>
    )
}
