export default function PhoneIcon() {
  return (
    <svg
      className="h-5 w-5 inline"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00008 15.0001H7.00842M2.83341 1.66675H11.1667C12.0872 1.66675 12.8334 2.41294 12.8334 3.33341V16.6667C12.8334 17.5872 12.0872 18.3334 11.1667 18.3334H2.83341C1.91294 18.3334 1.16675 17.5872 1.16675 16.6667V3.33341C1.16675 2.41294 1.91294 1.66675 2.83341 1.66675Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
